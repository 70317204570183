import React, { useState } from "react";
import Package from "./Package";

const recordPackage = [
  {
    title: "Single use",
    packageSize: [{ newPrice: 48 }],
    optionsList: [{ title: "Valid for", bigDescribtion: " 12 months" }],
  },
  {
    title: "Per use package",
    packageSize: [
      { title: 25, discount: 22.5, newPrice: "930", oldPrice: "1,200" },
      { title: 50, discount: 24.5, newPrice: "1,812", oldPrice: "2,400" },
      { title: 100, discount: 26.5, newPrice: "3,528", oldPrice: "4,800" },
    ],
    optionsList: [
      { title: "User management tool*" , link : "/products/deduklick?question=3#faqs"},
      { title: "Update search deduplication" },
      { title: "Customization*" },
      { title: "Training", smallDescribtion: " (Dedicated online workshop)" },
      { title: "Valid for", bigDescribtion: " 12 months" },
    ],
    hint:
      "*Additional charges will be applied based on your project requirements",
  },
  {
    title: "Per citation package",
    packageSize: [
      {
        title: "1,000,000",
        discount: 40,
        newPrice: "5,700",
        oldPrice: "9,500",
      },
    ],
    optionsList: [
      { title: "User management tool*" , link : "/products/deduklick?question=3#faqs"},
      { title: "Update search deduplication" },
      { title: "Customization*" },
      { title: "Training", smallDescribtion: " (Dedicated online workshop)" },
      { title: "Valid for", bigDescribtion: " 12 months" },
    ],
    hint:
      "*Additional charges will be applied based on your project requirements",
  },
];
const databasePackage = [
  {
    title: "Per use package",
    packageSize: [
      { title: 10, discount: 5, newPrice: "2,166", oldPrice: "2,280" },
      { title: 20, discount: 15, newPrice: "3,876", oldPrice: "4,560" },
      { title: 50, discount: 34.2, newPrice: "7,500", oldPrice: "11,400" },
    ],
    optionsList: [
      { title: "User management tool*" , link : "/products/deduklick?question=3#faqs"},
      { title: "Update search deduplication" },
      { title: "Customization*" },
      { title: "Training", smallDescribtion: " (Dedicated online workshop)" },
      { title: "Valid for", bigDescribtion: " 12 months" },
    ],
    hint:
      "*Additional charges will be applied based on your project requirements",
  },
];
const RenderedPackage = ({ plan }) => {
  const [active, setActive] = useState(false);

  if (plan === "record") {
    return (
      <React.Fragment>
        {recordPackage.map((item, i) => (
          <Package item={item} active={active} setActive={setActive} key={i} />
        ))}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {databasePackage.map((item, i) => (
          <Package item={item} active={active} setActive={setActive} key={i} />
        ))}
      </React.Fragment>
    );
  }
};
export default RenderedPackage;
