import { Link } from "gatsby";
import React from "react";
import styles from "./options.module.scss";

const Options = (props) => {
  const { optionsList, hint, active, setActive } = props;
  const optionItem = optionsList.map((item, i) => {
    return i < 2 ? (
      <li key={i}>
        {item?.link ? <Link to={item.link}>{item.title}</Link> : item.title}
        {item?.bigDescribtion && <b>{item.bigDescribtion}</b>}
      </li>
    ) : (
      active && (
        <li key={i}>
          {item.title}
          {item.smallDescribtion && <small> {item.smallDescribtion}</small>}
          {item.bigDescribtion && <b>{item.bigDescribtion}</b>}
        </li>
      )
    );
  });

  return (
    <div className={styles.options}>
      <ul className={styles.options__list}>{optionItem}</ul>

      {active && <p className={styles.options__hint}>{hint}</p>}

      {optionsList.length > 1 && (
        <button
          className={styles.options__btn}
          onClick={() => setActive(!active)}
        >
          <span>{active ? "less" : "more"}</span>
          <img
            src={require("../../../../../assets/images/icons/arrow-up.svg")}
            className={active ? "" : styles.options__btn__arrowDown}
          />
        </button>
      )}
    </div>
  );
};
export default Options;
