import React from "react";
import styles from "./help.module.scss";

const Help = () => {
  return (
    <div className={styles.help}>
      <div className={styles.help__content}>
        <p className={styles.help__content__title}>Find Your Perfect Plan</p>
        <div className={styles.help__content__describtion}>
          <p className={styles.help__content__describtion__line}>
            Contact our sales team to help you find the perfect plan and
            modules.
          </p>
          <a
            href={`mailto:support@risklick.ch`}
            rel="noopener noreferrer"
            className={styles.help__cta}
          >
            <span>Contact Us</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Help;
