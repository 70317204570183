import React from "react";
import styles from "./faq.module.scss";
import FaqItem from "./faqItem";
const faqItems = [
  {
    question: "What is the precision of the Deduklick solution?",
    answer:
      "Deduklick achieved average precision of 100.00%. In contrast, the manual deduplication process achieved average precision of 99.95%. On average, Deduklick’s speed performance is superior to the manual deduplication process. For instance, Deduklick required an average of a minute to perform deduplication, while experts needed an average 70 min to complete the task. Deduklick could simplify SRs production and represent important advantages for researchers, including saving time, increasing accuracy, reducing costs, and contributing to quality SRs.",
    link:
      "https://systematicreviewsjournal.biomedcentral.com/articles/10.1186/s13643-022-02045-9",
      linkTitle: "Click here to read the publication."
  },
  {
    question: "What is the account creation process?",
    answer:
      "If you are an individual user, you can directly sign up here and use the Deduklick solution; however, if you are an organization, you should contact us at support@risklick.ch to create an account tailored to your organization.",
  },
  {
    question: "What is Deduklick management tool?",
    answer:
      "The management tool is the organisation's dashboard. It allows administrators to create accounts for users within the organisation, purchase deduplication packages, and set deduplication limits.",
  },
  {
    question: "What are the payment options?",
    answer:
      "You can pay by credit card or by invoice. You can pay with a credit card once you have access to the management tool. The following tutorial will show you how to pay by credit card. ",
    link: "https://www.youtube.com/watch?v=JC7Z2Ne9xvk",
    linkTitle: "To watch the explanatory video, click here."
  },
  {
    question: 'How can you cite Deduklick in your publication?',
    answer: 'You can cite our peer-reviewed publication. See an example below:<br/><br/> Duplicate records were removed using a fully automated AI-based deduplication solution, Deduklick (1)<br/><br/>[1]. Borissov, N.; Haas, Q.; Minder, B.; Kopp-Heim, D.; von Gernler, M.; Janka, H.; Teodoro, D.; Amini, P. Reducing systematic review burden using Deduklick: A novel, automated, reliable, and explainable deduplication algorithm to foster medical research. Syst. Rev. 2022, 11, 172. <br/>https://doi.org/10.1186/s13643-022-02045-9 [Google Scholar] [CrossRef]'
  },
];
const FAQ = ({ openQuestion }) => {
  return (
    <div className={styles.faq}>
      {faqItems.map((item, i) => {
        return (
          <div className={styles.faq__item} key={i}>
            <FaqItem
              item={item}
              itemNumber={i}
              openQuestion={openQuestion}
            />
          </div>
        );
      })}
    </div>
  );
};
export default FAQ;
