import React from "react";
import { useWindowSize } from "../../../utills/hooks/useWindowSize";
import styles from "./pricing.module.scss";

const arrowsImageSrc = {
  right: {
    active: `${require("../../../assets/images/deduklick/arrow-circle-right-active.svg")}`,
    disable: `${require("../../../assets/images/deduklick/arrow-circle-right-disable.svg")}`,
  },
  left: {
    active: `${require("../../../assets/images/deduklick/arrow-circle-left-active.svg")}`,

    disable: `${require("../../../assets/images/deduklick/arrow-circle-left-disable.svg")}`,
  },
};
const RenderdPlan = ({ plan, setPlan }) => {
  const { width } = useWindowSize();

  const submitPlan = (plan) => {
    if (width > 768) setPlan(plan);
  }

  return (
    <React.Fragment>
      <div
        className={`${
          plan === "record"
            ? styles.pricing__plans_activePlan
            : styles.pricing__plans_hidden
        } ${styles.pricing__plans__item}`}
        onClick={()=>submitPlan("record")}
      >
        <div className={styles.pricing__plans__item__title}>
          {width < 768 && <img src={arrowsImageSrc.left.disable} />}

          <span>Search and Update search deduplication</span>

          {width < 768 && (
            <button
              onClick={() => {
                setPlan("database");
              }}
            >
              <img src={arrowsImageSrc.right.active} />
            </button>
          )}
        </div>
        <ul className={styles.pricing__plans__item__optionList}>
          <li>
            File contain up to <span>100,000 records</span>
          </li>
          <li>
            File size up to <span>100 MB</span>
          </li>
        </ul>
      </div>
      <div
        className={`${
          plan === "database"
            ? styles.pricing__plans_activePlan
            : styles.pricing__plans_hidden
        }  ${styles.pricing__plans__item}`}
        onClick={()=> submitPlan("database")}
      >
        <div className={styles.pricing__plans__item__title}>
          {width < 768 && (
            <button onClick={() => setPlan("record")}>
              <img src={arrowsImageSrc.left.active} />
            </button>
          )}
          <span>Database deduplication</span>
          {width < 768 && <img src={arrowsImageSrc.right.disable} />}
        </div>
        <ul className={styles.pricing__plans__item__optionList}>
          <li>
            File contain up to <span>2,000,000 records</span>
          </li>
          <li>
            File size up to <span>1 GB</span>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
export default RenderdPlan;
