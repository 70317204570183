import React from "react";
import { useOpenQuestion } from "../../../utills/hooks/useOpenQuestion";
import styles from "./faq.module.scss";

const FaqItem = (props) => {
  const { item, openQuestion, itemNumber } = props;
  const { active, setActive } = useOpenQuestion(openQuestion, itemNumber);

  return (
    <React.Fragment>
      <div
        className={styles.faqItem__question}
        onClick={() => setActive(!active)}
      >
        <p className={styles.faqItem__question__title}>
          {itemNumber + 1}. {item.question}
        </p>
        <button className={styles.faqItem__question__btn}>
          {active ? (
            <img
              src={require("../../../assets/images/deduklick/minus-circle.svg")}
            />
          ) : (
            <img
              src={require("../../../assets/images/deduklick/plus-circle.svg")}
            />
          )}
        </button>
      </div>
      {active && (
        <div className={styles.faqItem__answer}>
          <p dangerouslySetInnerHTML={{ __html: item.answer }} />

          {item.link && (
            <a href={item.link} rel="noopener noreferrer">
              {item.linkTitle}
            </a>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default FaqItem;

