import React, { useState } from "react";
import Help from "./Help";
import styles from "./pricing.module.scss";
import RenderedPackage from "./renderedPackage";
import RenderdPlan from "./renderedPlan";

const Pricing = () => {
  const [plan, setPlan] = useState("record");

  return (
    <div className={styles.pricing}>
      <div className={styles.pricing__plans}>
        <RenderdPlan plan={plan} setPlan={setPlan} />
      </div>
      <div className={styles.pricing__packages}>
        <RenderedPackage plan={plan} />
      </div>
      <Help />
    </div>
  );
};
export default Pricing;
