import React from "react";
import Options from "./Options";
import styles from "./package.module.scss";
import PackageSize from "./PackageSize";

const Package = (props) => {
  const { item, active, setActive } = props;
  return (
    <div
      className={styles.package__item} 
    >
      <PackageSize title={item?.title} packageSize={item?.packageSize} />
      <Options
        optionsList={item?.optionsList}
        hint={item?.hint}
        active={active}
        setActive={setActive}
      />
      <div className={styles.package__item__cta}>
        <a
          href={
            item?.title === "Single use"
              ? "https://www.risklick.ch/panel/request"
              : "https://docs.google.com/forms/d/1PLREvjBb3FmXgNoHs2eMn2tdrBPIy6sjT7Dd_ZApaNY/edit#response=ACYDBNjG2Am96i__muR1ScAVJZmuOkeJmwVBgmRyZQ4rKkkG4cr6-iSe22FflGL0OKNmq3Q"
          }
          rel="noopener noreferrer"
          className={styles.package__item__cta__btn}
        >
          <span>Register</span>
        </a>
      </div>
    </div>
  );
};
export default Package;
