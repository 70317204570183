import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', debounce(handleResize, 400));
    return () => window.removeEventListener('resize', () => {});
  }, []);
  
  return windowSize;
};
