import React, { useState } from "react";
import styles from "./package-size.module.scss";

const PackageSize = (props) => {
  const { title, packageSize } = props;
  const [activePS, setActivePS] = useState(0);
  return (
    <React.Fragment>
      {packageSize[activePS].discount && (
        <div className={styles.packageSize__discount}>
          <div className={styles.packageSize__discount_text}>
            - {packageSize[activePS].discount}%
          </div>
          <div className={styles.packageSize__discount_triangle}></div>
        </div>
      )}
      <div className={styles.packageSize__content}>
        <p className={styles.packageSize__content__title}>{title}</p>
        <p className={styles.packageSize__content__newPrice}>
          {packageSize[activePS].newPrice} CHF
        </p>
        {packageSize[activePS].oldPrice && (
          <p className={styles.packageSize__content__oldPrice}>
            {packageSize[activePS].oldPrice} CHF
          </p>
        )}
        {packageSize[0].title && (
          <div className={styles.packageSize__content__box}>
            {packageSize.map((item, i) => (
              <div
                key={i}
                className={
                  activePS === i ? styles.packageSize__content__box_active : undefined
                }
                onClick={() => setActivePS(i)}
              >
                {item.title}
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default PackageSize;
