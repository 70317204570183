import React from "react";
import { useWindowSize } from "../../../utills/hooks/useWindowSize";
import styles from "./instruction.module.scss";
const stepsList = [
  {
    imageSrc: `${require("../../../assets/images/deduklick/upload.svg")}`,
    title: "Step 1: Upload",
    describtion: ["RIS file"],
  },
  {
    imageSrc: `${require("../../../assets/images/deduklick/download.svg")}`,
    title: "Step 2: Download",
    describtion: [
      "Results (RIS),",
      "PRISMA flowchart,",
      "Deduplication report",
    ],
  },
];
const processImageSrc = {
  bigScreen: `${require("../../../assets/images/deduklick/time-process-right.svg")}`,
  smallScreen: `${require("../../../assets/images/deduklick/time-process-down.svg")}`,
};
const Instruction = () => {
  const { width } = useWindowSize();
  const renderdStep = (stepNumber) => {
    return (
      <React.Fragment>
        <img src={stepsList[stepNumber].imageSrc} />
        <p className={styles.instruction__content__item_title}>
          {stepsList[stepNumber].title}
        </p>
        <ul className={styles.instruction__content__item_description}>
          {stepsList[stepNumber].describtion.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </React.Fragment>
    );
  };
  return (
    <div className={styles.instruction}>
      <div className={styles.instruction__content}>
        <div className={styles.instruction__content__item}>
          {renderdStep(0)}
        </div>
        <div className={styles.instruction__content__item_process}>
          <img
            src={
              width > 768
                ? processImageSrc.bigScreen
                : processImageSrc.smallScreen
            }
          />
          <span>1 minute process</span>
        </div>
        <div className={styles.instruction__content__item}>
          {renderdStep(1)}
        </div>
      </div>
      <div className={styles.instruction__cta}>
        <a
          href="https://systematicreviewsjournal.biomedcentral.com/articles/10.1186/s13643-022-02045-9"
          rel="noopener noreferrer"
          className={styles.instruction__cta__btn}
        >
          <span>Peer reviewed publication</span>
        </a>
      </div>
    </div>
  );
};
export default Instruction;
