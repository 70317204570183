import React from "react";
import styles from "./benefits.module.scss";
const benefitsList = [
  {
    title: "Customizable",
    iconSrc: `${require("../../../assets/images/deduklick/customizable.svg")}`,
  },
  {
    title: "Reproducible",
    iconSrc: `${require("../../../assets/images/deduklick/reproducible.svg")}`,
  },
  {
    title: "Standardized",
    iconSrc: `${require("../../../assets/images/deduklick/standardized.svg")}`,
  },
  {
    title: "Scalable",
    iconSrc: `${require("../../../assets/images/deduklick/scalable.svg")}`,
  },
  {
    title: "Transparent",
    iconSrc: `${require("../../../assets/images/deduklick/transparent.svg")}`,
  },
  {
    title: "Report",
    iconSrc: `${require("../../../assets/images/deduklick/report.svg")}`,
  },
];
const Benefits = () => {
  const renderdBenefits =benefitsList.map((item,i)=>{
    return(<div className={styles.benefits__content__item} key={i}>
      <img className={styles.benefits__content__item__image} src={item.iconSrc}/>
      <p className={styles.benefits__content__item__title}>{item.title}</p>
    </div>)
  })
  return (
    <div className={styles.benefits}>
      <div className={styles.benefits__content}>{renderdBenefits}</div>
      <div className={styles.benefits__cta}>
        <a
          href="https://www.risklick.ch/panel/request"
          rel="noopener noreferrer"
          className={styles.benefits__cta__btn}
        >
          <span>Try For Free</span>
        </a>
      </div>
    </div>
  );
};
export default Benefits;
