import React, { useEffect, useState } from "react";
import Layout from "../../components/academy-layout";
import NewNavbar from "../../components/navbar";
import SEO from "../../components/seo";
import { Container } from "react-bootstrap";
import styles from "../../styles/pages/deduklick.module.scss";
import Benefits from "../../components/deduklick/Benefits";
import Instruction from "../../components/deduklick/Instruction";
import FAQ from "../../components/deduklick/FAQ";
// import Testemonial from "../../components/deduklick/Testemonial";
import Pricing from "../../components/deduklick/Pricing";
import { useWindowSize } from "../../utills/hooks/useWindowSize";
import { navigate } from "gatsby";

const Deduklick = () => {
  const { width } = useWindowSize();
  const searchParam = typeof window !== "undefined" && window.location.search;
  const params = new URLSearchParams(searchParam);
  const question = params?.get("question");
  const [openQuestion, setOpenQuestion] = useState(null);
  useEffect(() => {
    setOpenQuestion(question);
  }, [question]);

  return (
    <Layout>
      <SEO
        keywords={[
          "Artificial intelligence, Natural Language Processing, Systematic Review, Deduplication, Duplicate references, Automated Deduplication software, Explainable Duplicates Report, PRISM Flowchart, Transparent, Reproducible, Fast, Reliable, Systematic review software",
        ]}
        title="Deduklick - reliable, fast and transparent deduplication SaaS"
      />
      <NewNavbar />
      <div className={styles.deduklick}>
        <Container className={styles.deduklick__container}>
          <div className={styles.deduklick__intro}>
            <div className={styles.deduklick__intro__content}>
              <p>Deduklick</p>
              <p>
                Deduklick is a transparent fully automated deduplication
                solution created to efficiently remove duplicate records using
                AI.
              </p>
              <div className={styles.deduklick__cta}>
                <a
                  href="https://www.risklick.ch/panel/request"
                  rel="noopener noreferrer"
                  className={styles.deduklick__cta__btn}
                >
                  <span className="blue-bg">Try for free</span>
                </a>
                <span className={styles.deduklick__cta__hint}>
                  No credit card required
                </span>
              </div>
            </div>
            <img
              className={styles.deduklick__intro__image}
              src={require("../../assets/images/deduklick/banner.svg")}
            />
          </div>
        </Container>
        <Container className={styles.deduklick__container}>
          <div
            className={styles.deduklick__offers}
            onClick={() => setOpenQuestion(1)}
          >
            <div className={styles.deduklick__offers__box}>
              <a href="#faqs" rel="noopener noreferrer">
                <img src={require("../../assets/images/deduklick/fast.svg")} />

                <span className={styles.deduklick__offers__box__description}>
                  <span>100X</span>
                  <span>Faster*</span>
                </span>

                {width > 768 && (
                  <span className={styles.deduklick__offers__box__hint}>
                    *For more information check the FAQ
                  </span>
                )}
              </a>
            </div>
            <div className={styles.deduklick__offers__box}>
              <a href="#faqs" rel="noopener noreferrer">
                <img src={require("../../assets/images/deduklick/money.svg")} />
                <span className={styles.deduklick__offers__box__description}>
                  <span>10X</span>
                  <span>Cheaper*</span>
                </span>
              </a>
            </div>
            <div className={styles.deduklick__offers__box}>
              <a href="#faqs" rel="noopener noreferrer">
                <img
                  src={require("../../assets/images/deduklick/quality.svg")}
                />
                <span className={styles.deduklick__offers__box__description}>
                  <span>100%</span>
                  <span>Precision*</span>
                </span>
              </a>
            </div>
            {width < 768 && (
              <p className={styles.deduklick__hint}>
                *For more information check the FAQ
              </p>
            )}
          </div>
        </Container>

        <div className={styles.deduklick__instruction}>
          <p className={styles.deduklick__section_title}>Just drag & drop</p>
          <Instruction />
        </div>
        <Container className={styles.deduklick__container}>
          <div className={styles.deduklick__benefits}>
            <p className={styles.deduklick__section_title}>Why Deduklick?</p>
            <Benefits />
          </div>
        </Container>

        <div className={styles.deduklick__pricing}>
          <p className={styles.deduklick__section_title}>Pricing</p>
          <Pricing />
        </div>
        {/* <div className={styles.deduklick__testimonials}>
        <p className={styles.deduklick__section_title}>Client Testimonials</p>
        <Testemonial />
      </div> */}
        <Container className={styles.deduklick__container}>
          <div className={styles.deduklick__faqs} id="faqs">
            <p className={styles.deduklick__section_title}>FAQs</p>
            <FAQ openQuestion={openQuestion} />
            <a className={styles.deduklick__faqs__link}>
              See most received questions
            </a>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Deduklick;
